<template>
  <div>
    <v-card-title class="pb-0">DOCUMENTS</v-card-title>
    <v-divider></v-divider>
    <v-card class="mt-3" flat id="uploadedDocuments">
      <v-dialog v-model="dialogDeleteDoc" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">CONFIRM DELETE?</v-card-title>
          <v-row no-gutters class="ml-4">
            <v-col cols="12"> File Name: {{ editedItem.name }} </v-col>
            <br />
            <v-col cols="12"> File Type: {{ editedItem.Type }} </v-col> <br />
            <v-col cols="12"> File Remark: {{ editedItem.Remark }} </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="primary" @click="closeDeleteDoc">Cancel</v-btn>
            <v-btn dark color="red darken-1" @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--INTITIAL DOCUMENTS -->
      <div class="red lighten-5">
        <v-row>
          <v-col cols="6">
            <v-card-title class="justify-center">
              INITIAL DOCUMENT(S)
            </v-card-title>
          </v-col>

          <v-col cols="6">
            <v-dialog v-model="dialogUploadDoc" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="UploadAccess"
                  outlined
                  large
                  class="my-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="uploadDoc()"
                >
                  UPLOAD DOCUMENTS
                </v-btn>
              </template>
              <v-card tile>
                <v-card-title class="justify-center text-h5 grey lighten-2"
                  >UPLOAD INITIAL DOCUMENT</v-card-title
                >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-file-input
                          accept="image/*,.pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          show-size
                          truncate-length="50"
                          placeholder="Select Document"
                          prepend-icon="mdi-camera"
                          label="Document"
                          @change="selectFile"
                          name="file"
                          v-model="currentFile"
                          :rules="rules"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          suffix="required"
                          placeholder="Select A Type"
                          :items="selectTypes"
                          v-model="uploads.Type"
                          clearable
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="uploads.Remark"
                          label="Document Remark"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          small
                          tile
                          @click="closeUploadDoc"
                          class="mt-3 mr-2"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          :disabled="isUploadDocDisabled"
                          small
                          tile
                          class="success mt-3"
                          @click="uploadDocConfirm"
                          >Submit</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>

        <v-card v-if="filterCase(documents).length > 0">
          <!-- <v-card-title>DOCUMENTS</v-card-title> -->
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-left">FILE NAME</th>
                <th class="text-left">FILE TYPE</th>
                <th class="text-left">FILE REMARK</th>
                <th class="text-left">UPLOADED BY</th>
                <th class="text-left">UPLOADED AT</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="doc in filterCase(documents)" :key="doc.id">
                <td class="text-left">
                  <v-btn outlined x-small block @click="onClick(doc.name)">{{
                    doc.name
                  }}</v-btn>
                </td>

                <td class="text-left">
                  {{ doc.Type }}
                </td>
                <td class="text-left">{{ doc.Remark }}</td>
                <td
                  v-for="uploader in filterUser(doc.createdBy)"
                  :key="uploader.id"
                  class="text-left"
                >
                  {{ uploader.username }}
                </td>
                <td class="text-left">{{ formatDate(doc.createdAt) }}</td>
                <td class="text-left">
                  <v-tooltip bottom transition="fab-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        color="red darken-1"
                        v-if="management"
                        @click="deleteDoc(doc)"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </template>
                    <span> delete</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </div>
      <!--AFTER INVESTIGATION DOCUMENTS -->
      <div class="orange lighten-5">
        <v-row>
          <v-col cols="6">
            <v-card-title class="justify-center">
              ADJUSTER'S REPORT(S)
            </v-card-title>
          </v-col>

          <v-col cols="6">
            <v-dialog v-model="dialogUploadDoc1" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="adjUploadAccess"
                  outlined
                  large
                  class="my-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="uploadDoc1()"
                >
                  UPLOAD DOCUMENTS
                </v-btn>
              </template>
              <v-card tile>
                <v-card-title class="justify-center text-h5 grey lighten-2"
                  >UPLOAD ADJUSTER'S REPORT</v-card-title
                >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-file-input
                          accept="image/*,.pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          show-size
                          truncate-length="50"
                          placeholder="Select Document"
                          prepend-icon="mdi-camera"
                          label="Document"
                          @change="selectFile"
                          name="file"
                          v-model="currentFile"
                          :rules="rules"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          suffix="required"
                          placeholder="Select A Type"
                          :items="selectTypes"
                          v-model="uploads.Type"
                          clearable
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="uploads.Remark"
                          label="Document Remark"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          small
                          tile
                          @click="closeUploadDoc1"
                          class="mt-3 mr-2"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          :disabled="isUploadDocDisabled"
                          small
                          tile
                          class="success mt-3"
                          @click="uploadDocConfirm1"
                          >Submit</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>

        <v-card v-if="filterCase1(documents).length > 0">
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-left">FILE NAME</th>
                <th class="text-left">FILE TYPE</th>
                <th class="text-left">FILE REMARK</th>
                <th class="text-left">UPLOADED BY</th>
                <th class="text-left">UPLOADED AT</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="doc in filterCase1(documents)" :key="doc.id">
                <td class="text-left">
                  <v-btn outlined x-small block @click="onClick(doc.name)">{{
                    doc.name
                  }}</v-btn>
                </td>

                <td class="text-left">
                  {{ doc.Type }}
                </td>
                <td class="text-left">{{ doc.Remark }}</td>
                <td
                  v-for="uploader in filterUser(doc.createdBy)"
                  :key="uploader.id"
                  class="text-left"
                >
                  {{ uploader.username }}
                </td>
                <td class="text-left">{{ formatDate(doc.createdAt) }}</td>
                <td class="text-left">
                  <v-tooltip bottom transition="fab-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        color="red darken-1"
                        v-if="management"
                        @click="deleteDoc(doc)"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </template>
                    <span> delete</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </div>
      <!--EDITED DOCUMENTS -->
      <div class="yellow lighten-5">
        <v-row>
          <v-col cols="6">
            <v-card-title class="justify-center">
              EDITED REPORT(S)
            </v-card-title>
          </v-col>

          <v-col cols="6">
            <v-dialog v-model="dialogUploadDoc2" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="ediUploadAccess"
                  outlined
                  large
                  class="my-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="uploadDoc2()"
                >
                  UPLOAD DOCUMENTS
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="justify-center text-h5 grey lighten-2"
                  >UPLOAD EDITED REPORT</v-card-title
                >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-file-input
                          accept="image/*,.pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          show-size
                          truncate-length="50"
                          placeholder="Select Document"
                          prepend-icon="mdi-camera"
                          label="Document"
                          @change="selectFile"
                          name="file"
                          v-model="currentFile"
                          :rules="rules"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          suffix="required"
                          placeholder="Select A Type"
                          :items="selectTypes"
                          v-model="uploads.Type"
                          clearable
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="uploads.Remark"
                          label="Document Remark"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          small
                          tile
                          @click="closeUploadDoc2"
                          class="mt-3 mr-2"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          :disabled="isUploadDocDisabled"
                          small
                          tile
                          class="success mt-3"
                          @click="uploadDocConfirm2"
                          >Submit</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>

        <v-card v-if="filterCase2(documents).length > 0">
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-left">FILE NAME</th>
                <th class="text-left">FILE TYPE</th>
                <th class="text-left">FILE REMARK</th>
                <th class="text-left">UPLOADED BY</th>
                <th class="text-left">UPLOADED AT</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="doc in filterCase2(documents)" :key="doc.id">
                <td class="text-left">
                  <v-btn outlined x-small block @click="onClick(doc.name)">{{
                    doc.name
                  }}</v-btn>
                </td>

                <td class="text-left">
                  {{ doc.Type }}
                </td>
                <td class="text-left">{{ doc.Remark }}</td>
                <td
                  v-for="uploader in filterUser(doc.createdBy)"
                  :key="uploader.id"
                  class="text-left"
                >
                  {{ uploader.username }}
                </td>
                <td class="text-left">{{ formatDate(doc.createdAt) }}</td>
                <td class="text-left">
                  <v-tooltip bottom transition="fab-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        color="red darken-1"
                        v-if="management"
                        @click="deleteDoc(doc)"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </template>
                    <span> delete</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </div>
      <!--FINAL DOCUMENTS -->
      <div class="green lighten-5">
        <v-row>
          <v-col cols="6">
            <v-card-title class="justify-center">
              FINAL REPORT(S)
            </v-card-title>
          </v-col>

          <v-col cols="6">
            <v-dialog v-model="dialogUploadDoc3" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="UploadAccess"
                  outlined
                  large
                  class="my-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="uploadDoc3()"
                >
                  UPLOAD DOCUMENTS
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="justify-center text-h5 grey lighten-2"
                  >UPLOAD FINAL REPORT</v-card-title
                >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-file-input
                          accept="image/*,.pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          show-size
                          truncate-length="50"
                          placeholder="Select Document"
                          prepend-icon="mdi-camera"
                          label="Document"
                          @change="selectFile"
                          name="file"
                          v-model="currentFile"
                          :rules="rules"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          suffix="required"
                          placeholder="Select A Type"
                          :items="selectTypes"
                          v-model="uploads.Type"
                          clearable
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="uploads.Remark"
                          label="Document Remark"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          small
                          tile
                          @click="closeUploadDoc3"
                          class="mt-3 mr-2"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          :disabled="isUploadDocDisabled"
                          small
                          tile
                          class="success mt-3"
                          @click="uploadDocConfirm3"
                          >Submit</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>

        <v-card v-if="filterCase3(documents).length > 0">
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-left">FILE NAME</th>
                <th class="text-left">FILE TYPE</th>
                <th class="text-left">FILE REMARK</th>
                <th class="text-left">UPLOADED BY</th>
                <th class="text-left">UPLOADED AT</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="doc in filterCase3(documents)" :key="doc.id">
                <td class="text-left">
                  <v-btn outlined x-small block @click="onClick(doc.name)">{{
                    doc.name
                  }}</v-btn>
                </td>

                <td class="text-left">
                  {{ doc.Type }}
                </td>
                <td class="text-left">{{ doc.Remark }}</td>
                <td
                  v-for="uploader in filterUser(doc.createdBy)"
                  :key="uploader.id"
                  class="text-left"
                >
                  {{ uploader.username }}
                </td>
                <td class="text-left">{{ formatDate(doc.createdAt) }}</td>
                <td class="text-left">
                  <v-tooltip bottom transition="fab-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        color="red darken-1"
                        v-if="management"
                        @click="deleteDoc(doc)"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </template>
                    <span> delete</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </div>
    </v-card>
  </div>
</template>
<script>
import DocsDataService from "../../services/DocsDataService";
import UploadService from "../../services/UploadDocsService";
import MemberDataService from "../../services/MemberDataService";
import axios from "axios";
import moment from "moment";

export default {
  name: "documents",
  props: ["casefile", "refType", "insurer", "branches"],
  data() {
    return {
      currentFile: undefined,
      progress: 0,
      message: "",
      management: false,
      fileInfos: [],
      rules: [
        (value) =>
          !value ||
          value.size < 4000000 ||
          "File size should be less than 4 MB!",
      ],
      documents: [],
      editedIndex: -1,
      editedItem: {
        id: null,
        caseId: null,
        docStageId: null,
        name: "",
        Type: "",
        Remark: "",
        createdBy: null,
      },
      members: [],
      selectTypes: [
        "Police Reports",
        "JPJ Report",
        "Police Out-Come",
        "Employment letters",
        "Court Documents",
        "Statutory Declaration",
        "Cover Note / Policy",
        "Other Claim Documents",
        "Adjusters Report & Documents",
      ],
      uploads: {
        id: null,
        caseId: "",
        name: "",
        Type: "",
        Remark: "",
        createdBy: "",
      },
      dialogUploadDoc: false,
      dialogUploadDoc1: false,
      dialogUploadDoc2: false,
      dialogUploadDoc3: false,
      dialogDeleteDoc: false,
      dialogDeleteDoc1: false,
      dialogDeleteDoc2: false,
      dialogDeleteDoc3: false,
      UploadAccess: false,
      adjUploadAccess: false,
      ediUploadAccess: false,
    };
  },

  computed: {
    isUploadDocDisabled() {
      return !this.uploads.Type;
    },
  },
  watch: {
    uploadDoc(val) {
      val || this.closeUploadDoc();
    },
    uploadDoc1(val) {
      val || this.closeUploadDoc1();
    },
    uploadDoc2(val) {
      val || this.closeUploadDoc2();
    },
    uploadDoc3(val) {
      val || this.closeUploadDoc3();
    },
    deleteDoc(val) {
      val || this.closeDeleteDoc();
    },
  },
  methods: {
    checkUploadAccess() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHCLERK`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHMANAGER`)
      ) {
        this.UploadAccess = true;
      }
    },
    checkAdjUploadAccess() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHCLERK`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHMANAGER`) ||
        this.$store.state.auth.user.roles.includes(`ADJUSTER`)
      ) {
        this.adjUploadAccess = true;
      }
    },
    checkEdiUploadAccess() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHCLERK`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHMANAGER`) ||
        this.$store.state.auth.user.roles.includes(`EDITOR`)
      ) {
        this.ediUploadAccess = true;
      }
    },
    checkManagement() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`)
      ) {
        this.management = true;
      }
    },
    onClick(item) {
      axios({
        // url: "http://localhost:8080/docs/" + item, // Development
        url: "/docs/" + item, // Production
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fURL = document.createElement("a");

        fURL.href = fileURL;

        var ext = item.substr(item.lastIndexOf(".") + 1);

        var caseVehicleNo = this.casefile.vehicleNo.toUpperCase();

        // fURL.setAttribute("download", "file.png");
        fURL.setAttribute(
          "download",
          caseVehicleNo + "_" + this.casefile.id + "." + ext
        );
        document.body.appendChild(fURL);

        fURL.click();
      });
    },
    moment: function (date) {
      return moment(date);
    },
    formatDate: function (date) {
      return moment(date).format("DD/MM/YYYY, h:mm:ss a");
    },
    refreshList() {
      this.retrieveDocs();
    },
    filterCaseFile() {
      return this.casefile.filter((casef) => casef.id === this.casefile.id);
    },
    filterCase() {
      return this.documents.filter(
        (doc) => doc.caseId === this.casefile.id && doc.docStageId === 1
      );
    },
    filterCase1() {
      return this.documents.filter(
        (doc) => doc.caseId === this.casefile.id && doc.docStageId === 2
      );
    },
    filterCase2() {
      return this.documents.filter(
        (doc) => doc.caseId === this.casefile.id && doc.docStageId === 3
      );
    },
    filterCase3() {
      return this.documents.filter(
        (doc) => doc.caseId === this.casefile.id && doc.docStageId === 4
      );
    },
    filterUser(item) {
      return this.members.filter((member) => member.id === item);
    },
    selectFile(file) {
      // this.progress = 0;
      this.currentFile = file;
    },
    retrieveDocs() {
      DocsDataService.getAll()
        .then((response) => {
          this.documents = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveUser() {
      MemberDataService.getAll()
        .then((response) => {
          this.members = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    uploadDoc() {
      this.dialogUploadDoc = true;
    },
    uploadDoc1() {
      this.dialogUploadDoc1 = true;
    },
    uploadDoc2() {
      this.dialogUploadDoc2 = true;
    },
    uploadDoc3() {
      this.dialogUploadDoc3 = true;
    },
    deleteDoc(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDeleteDoc = true;
    },
    closeUploadDoc() {
      this.dialogUploadDoc = false;
      this.$nextTick(() => {});
    },
    closeUploadDoc1() {
      this.dialogUploadDoc1 = false;
      this.$nextTick(() => {});
    },
    closeUploadDoc2() {
      this.dialogUploadDoc2 = false;
      this.$nextTick(() => {});
    },
    closeUploadDoc3() {
      this.dialogUploadDoc3 = false;
      this.$nextTick(() => {});
    },
    closeDeleteDoc() {
      this.dialogDeleteDoc = false;
      this.$nextTick(() => {});
    },
    closeDeleteDoc1() {
      this.dialogDeleteDoc1 = false;
      this.$nextTick(() => {});
    },
    closeDeleteDoc2() {
      this.dialogDeleteDoc2 = false;
      this.$nextTick(() => {});
    },
    closeDeleteDoc3() {
      this.dialogDeleteDoc3 = false;
      this.$nextTick(() => {});
    },
    deleteItemConfirm() {
      DocsDataService.delete(this.editedItem.id, this.editedItem.name)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeDeleteDoc();
    },
    uploadDocConfirm() {
      if (!this.currentFile) {
        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "warning",
          text: "Please select a file!",
        });
        return;
      }
      if (this.currentFile) {
        this.message = "";
        UploadService.upload(this.currentFile, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
          .then((response) => {
            // this.message = response.data.message;
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            return UploadService.getFiles();
          })
          .then((files) => {
            this.fileInfos = files.data;
            // this.members.profile = files.data[0];
            this.uploads.name = files.data.slice(-1)[0].name;
            // console.log(this.members.profile);
            // this.message = "Image uploaded successfully";

            // this.retrieveMembers();
            this.currentFile = undefined;
            var data = {
              caseId: this.casefile.id,
              docStageId: 1,
              name: this.uploads.name,
              Type: this.uploads.Type,
              Remark: this.uploads.Remark,
              createdBy: this.$store.state.auth.user.id,
            };

            DocsDataService.create(data)
              .then((response) => {
                this.uploads.id = response.data.id;
                this.closeUploadDoc();
                this.$store.dispatch("setSnackbar", {
                  showing: true,
                  color: "success",
                  text:
                    (response && response.data && response.data.message) ||
                    toString(),
                });
                this.uploads.Type = "";
                this.uploads.Remark = "";
                this.refreshList();
              })
              .catch((e) => {
                this.$store.dispatch("setSnackbar", {
                  showing: true,
                  color: "warning",
                  text:
                    (e.response &&
                      e.response.data &&
                      e.response.data.message) ||
                    e.message ||
                    toString(),
                });
              });
          })
          .catch(() => {
            this.progress = 0;
            this.message = "Could not upload the file!";
            this.currentFile = undefined;
          });
      }
    },
    uploadDocConfirm1() {
      if (!this.currentFile) {
        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "warning",
          text: "Please select a file!",
        });
        return;
      }
      if (this.currentFile) {
        this.message = "";
        UploadService.upload(this.currentFile, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
          .then((response) => {
            // this.message = response.data.message;
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            return UploadService.getFiles();
          })
          .then((files) => {
            this.fileInfos = files.data;
            // this.members.profile = files.data[0];
            this.uploads.name = files.data.slice(-1)[0].name;
            // console.log(this.members.profile);
            // this.message = "Image uploaded successfully";

            // this.retrieveMembers();
            this.currentFile = undefined;
            var data = {
              caseId: this.casefile.id,
              docStageId: 2,
              name: this.uploads.name,
              Type: this.uploads.Type,
              Remark: this.uploads.Remark,
              createdBy: this.$store.state.auth.user.id,
            };

            DocsDataService.create(data)
              .then((response) => {
                this.uploads.id = response.data.id;
                this.closeUploadDoc1();
                this.$store.dispatch("setSnackbar", {
                  showing: true,
                  color: "success",
                  text:
                    (response && response.data && response.data.message) ||
                    toString(),
                });
                this.uploads.Type = "";
                this.uploads.Remark = "";
                this.refreshList();
              })
              .catch((e) => {
                this.$store.dispatch("setSnackbar", {
                  showing: true,
                  color: "warning",
                  text:
                    (e.response &&
                      e.response.data &&
                      e.response.data.message) ||
                    e.message ||
                    toString(),
                });
              });
          })
          .catch(() => {
            this.progress = 0;
            this.message = "Could not upload the file!";
            this.currentFile = undefined;
          });
      }
    },
    uploadDocConfirm2() {
      if (!this.currentFile) {
        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "warning",
          text: "Please select a file!",
        });
        return;
      }
      if (this.currentFile) {
        this.message = "";
        UploadService.upload(this.currentFile, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
          .then((response) => {
            // this.message = response.data.message;
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            return UploadService.getFiles();
          })
          .then((files) => {
            this.fileInfos = files.data;
            // this.members.profile = files.data[0];
            this.uploads.name = files.data.slice(-1)[0].name;
            // console.log(this.members.profile);
            // this.message = "Image uploaded successfully";

            // this.retrieveMembers();
            this.currentFile = undefined;
            var data = {
              caseId: this.casefile.id,
              docStageId: 3,
              name: this.uploads.name,
              Type: this.uploads.Type,
              Remark: this.uploads.Remark,
              createdBy: this.$store.state.auth.user.id,
            };

            DocsDataService.create(data)
              .then((response) => {
                this.uploads.id = response.data.id;
                this.closeUploadDoc2();
                this.$store.dispatch("setSnackbar", {
                  showing: true,
                  color: "success",
                  text:
                    (response && response.data && response.data.message) ||
                    toString(),
                });
                this.uploads.Type = "";
                this.uploads.Remark = "";
                this.refreshList();
              })
              .catch((e) => {
                this.$store.dispatch("setSnackbar", {
                  showing: true,
                  color: "warning",
                  text:
                    (e.response &&
                      e.response.data &&
                      e.response.data.message) ||
                    e.message ||
                    toString(),
                });
              });
          })
          .catch(() => {
            this.progress = 0;
            this.message = "Could not upload the file!";
            this.currentFile = undefined;
          });
      }
    },
    uploadDocConfirm3() {
      if (!this.currentFile) {
        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "warning",
          text: "Please select a file!",
        });
        return;
      }
      if (this.currentFile) {
        this.message = "";
        UploadService.upload(this.currentFile, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
          .then((response) => {
            // this.message = response.data.message;
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            return UploadService.getFiles();
          })
          .then((files) => {
            this.fileInfos = files.data;
            // this.members.profile = files.data[0];
            this.uploads.name = files.data.slice(-1)[0].name;
            // console.log(this.members.profile);
            // this.message = "Image uploaded successfully";

            // this.retrieveMembers();
            this.currentFile = undefined;
            var data = {
              caseId: this.casefile.id,
              docStageId: 4,
              name: this.uploads.name,
              Type: this.uploads.Type,
              Remark: this.uploads.Remark,
              createdBy: this.$store.state.auth.user.id,
            };

            DocsDataService.create(data)
              .then((response) => {
                this.uploads.id = response.data.id;
                this.closeUploadDoc3();
                this.$store.dispatch("setSnackbar", {
                  showing: true,
                  color: "success",
                  text:
                    (response && response.data && response.data.message) ||
                    toString(),
                });
                this.uploads.Type = "";
                this.uploads.Remark = "";
                this.refreshList();
              })
              .catch((e) => {
                this.$store.dispatch("setSnackbar", {
                  showing: true,
                  color: "warning",
                  text:
                    (e.response &&
                      e.response.data &&
                      e.response.data.message) ||
                    e.message ||
                    toString(),
                });
              });
          })
          .catch(() => {
            this.progress = 0;
            this.message = "Could not upload the file!";
            this.currentFile = undefined;
          });
      }
    },
  },
  mounted() {
    this.retrieveDocs();
    this.retrieveUser();
    this.checkManagement();
    this.checkUploadAccess();
    this.checkAdjUploadAccess();
    this.checkEdiUploadAccess();
  },
};
</script>
